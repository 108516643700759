var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-2",
                      attrs: { plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("hideTicketDetails")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "grey--text text--darken-4 text-h5 font-weight-medium mt-3",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.ticketData.center_ticket_id) +
                          " - " +
                          _vm._s(_vm.ticketData.revision)
                      ),
                    ]
                  ),
                  _vm.$vuetify.breakpoint.mdAndUp &&
                  _vm.checkPermission("secondaryFunctions")
                    ? _c(
                        "v-col",
                        { staticClass: "ml-8 mt-1 d-flex justify-end pt-0" },
                        [
                          _vm.companyExcavatorType &&
                          _vm.ticketData.state &&
                          _vm.ticketData.state === "NC"
                            ? _c("SecondaryFunctionsNC", {
                                attrs: { "ticket-details": _vm.ticketData },
                                on: {
                                  getTicketFromCenter: _vm.getTicketFromCenter,
                                  retransmitTicketSuccess:
                                    _vm.retransmitTicketSuccess,
                                  cancelTicketSuccess: _vm.cancelTicketSuccess,
                                  hourNoticeTicketSuccess:
                                    _vm.hourNoticeTicketSuccess,
                                },
                              })
                            : _vm._e(),
                          _vm.companyExcavatorType &&
                          _vm.ticketData.state &&
                          _vm.ticketData.state === "OH"
                            ? _c("SecondaryFunctionsOH", {
                                attrs: { "ticket-details": _vm.ticketData },
                                on: {
                                  getTicketFromCenter: _vm.getTicketFromCenter,
                                  repeatTicketSuccess: _vm.repeatTicketSuccess,
                                  cancelTicketSuccess: _vm.cancelTicketSuccess,
                                },
                              })
                            : _vm._e(),
                          _vm.companyExcavatorType &&
                          _vm.ticketData.state &&
                          _vm.ticketData.state === "IL"
                            ? _c("SecondaryFunctionJulie", {
                                attrs: { "ticket-details": _vm.ticketData },
                                on: {
                                  getTicketFromCenter: _vm.getTicketFromCenter,
                                  retransmitTicketSuccess:
                                    _vm.retransmitTicketSuccess,
                                  cancelTicketSuccess: _vm.cancelTicketSuccess,
                                  extendTicketSuccess: _vm.extendTicketSuccess,
                                  secondNoticeTicketSuccess:
                                    _vm.secondNoticeTicketSuccess,
                                },
                              })
                            : _vm._e(),
                          _vm.companyExcavatorType &&
                          _vm.ticketData.state &&
                          _vm.ticketData.state === "CO"
                            ? _c("SecondaryFunctionsCO", {
                                attrs: { "ticket-details": _vm.ticketData },
                                on: {
                                  getTicketFromCenter: _vm.getTicketFromCenter,
                                  addMembersTicketSuccess:
                                    _vm.addMembersTicketSuccess,
                                  cancelTicketSuccess: _vm.cancelTicketSuccess,
                                  extendTicketSuccess: _vm.extendTicketSuccess,
                                  relocateRefreshTicketSuccess:
                                    _vm.relocateRefreshTicketSuccess,
                                  commentTicketSuccess:
                                    _vm.commentTicketSuccess,
                                  secondNoticeTicketSuccess:
                                    _vm.secondNoticeTicketSuccess,
                                  utilityNotFoundTicketSuccess:
                                    _vm.utilityNotFoundTicketSuccess,
                                },
                              })
                            : _vm._e(),
                          _vm.companyExcavatorType &&
                          _vm.ticketData.state &&
                          _vm.ticketData.state === "VA"
                            ? _c("SecondaryFunctionVups", {
                                attrs: { "ticket-details": _vm.ticketData },
                                on: {
                                  getTicketFromCenter: _vm.getTicketFromCenter,
                                  addMembersTicketSuccess:
                                    _vm.addMembersTicketSuccess,
                                  cancelTicketSuccess: _vm.cancelTicketSuccess,
                                  extendTicketSuccess: _vm.extendTicketSuccess,
                                  relocateRefreshTicketSuccess:
                                    _vm.relocateRefreshTicketSuccess,
                                  commentTicketSuccess:
                                    _vm.commentTicketSuccess,
                                  secondNoticeTicketSuccess:
                                    _vm.secondNoticeTicketSuccess,
                                  utilityNotFoundTicketSuccess:
                                    _vm.utilityNotFoundTicketSuccess,
                                  hourDeepNoticeTicketSuccess:
                                    _vm.hourDeepNoticeTicketSuccess,
                                  hourNoticeTicketSuccess:
                                    _vm.hourNoticeTicketSuccess,
                                  remarkTicketSuccess: _vm.remarkTicketSuccess,
                                  correctionTicketSuccess:
                                    _vm.correctionTicketSuccess,
                                  updateTicketSuccess: _vm.updateTicketSuccess,
                                },
                              })
                            : _vm._e(),
                          _vm.companyExcavatorType &&
                          _vm.ticketData.state &&
                          _vm.ticketData.state === "UT"
                            ? _c("SecondaryFunctionUtah", {
                                attrs: { "ticket-details": _vm.ticketData },
                                on: {
                                  getTicketFromCenter: _vm.getTicketFromCenter,
                                  addMembersTicketSuccess:
                                    _vm.addMembersTicketSuccess,
                                  cancelTicketSuccess: _vm.cancelTicketSuccess,
                                  extendTicketSuccess: _vm.extendTicketSuccess,
                                  remarkTicketSuccess: _vm.remarkTicketSuccess,
                                  commentTicketSuccess:
                                    _vm.commentTicketSuccess,
                                  secondNoticeTicketSuccess:
                                    _vm.secondNoticeTicketSuccess,
                                  retransmitTicketSuccess:
                                    _vm.retransmitTicketSuccess,
                                  noResponseTicketSuccess:
                                    _vm.noResponseTicketSuccess,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "ml-16", attrs: { cols: "5" } },
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "mr-4 rounded-lg",
                          attrs: { label: "", outlined: "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.ticketData.center_id
                                  ? _vm.centerMap[_vm.ticketData.center_id]
                                      .center_name
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-chip",
                        {
                          staticClass: "mr-4 rounded-lg",
                          attrs: { label: "", outlined: "" },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                small: "",
                                color: _vm.priorityIconColor(
                                  _vm.ticketData.priority
                                ),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.priorityIcon(_vm.ticketData.priority)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _vm._v(" " + _vm._s(_vm.ticketData.priority) + " "),
                        ],
                        1
                      ),
                      _c(
                        "v-chip",
                        {
                          staticClass: "rounded-lg mr-4",
                          attrs: { label: "", outlined: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.ticketData.type) + " ")]
                      ),
                      _c(
                        "v-chip",
                        {
                          staticClass: "rounded-lg",
                          attrs: { label: "", outlined: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.ticketData.category) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("v-col", { staticClass: "d-flex justify-end" }),
                ],
                1
              ),
              _c("v-row", [_c("v-divider")], 1),
              _c(
                "div",
                { staticClass: "mt-8 pl-10" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pa-0 text-body-2 text--darken-2 font-weight-regular mr-2",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("user")) + ": ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.ticketData.user_id
                                  ? _vm.companyUserMap[_vm.ticketData.user_id]
                                      .name
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-divider", {
                        staticClass: "mr-4",
                        attrs: { vertical: "" },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pa-0 text-body-2 text--darken-2 font-weight-regular mr-2",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("createdOnText")) + ": ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .moment(_vm.ticketData.created_at)
                                  .format("MM/DD/YYYY, hh:mm a")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-divider", {
                        staticClass: "mr-4",
                        attrs: { vertical: "" },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pa-0 text-body-2 text--darken-2 font-weight-regular mr-2",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("associatedProject")) + ": "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                        },
                        [_vm._v(" " + _vm._s(_vm.associateProject) + " ")]
                      ),
                      _c("div", { attrs: { cols: "4" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pt-2 px-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "", "min-width": "40" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("hideTicketDetails")
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                        1
                      ),
                      _c("v-col", { staticClass: "d-flex justify-end" }),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pl-5 pr-4", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "grey--text text--darken-4 text-h5 font-weight-medium mt-3",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.ticketData.center_ticket_id) +
                              " - " +
                              _vm._s(_vm.ticketData.revision)
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "pl-5 pr-6 mt-4 pb-4",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "mr-4 rounded-lg",
                              attrs: { label: "", outlined: "" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ticketData.center_id
                                      ? _vm.centerMap[_vm.ticketData.center_id]
                                          .center_name
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-chip",
                            {
                              staticClass: "mr-4 rounded-lg",
                              attrs: { label: "", outlined: "" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    small: "",
                                    color: _vm.priorityIconColor(
                                      _vm.ticketData.priority
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.priorityIcon(
                                          _vm.ticketData.priority
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.ticketData.priority) + " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-chip",
                            {
                              staticClass: "mr-4 rounded-lg",
                              attrs: { label: "", outlined: "" },
                            },
                            [_vm._v(" " + _vm._s(_vm.ticketData.type) + " ")]
                          ),
                          _c(
                            "v-chip",
                            {
                              staticClass: "rounded-lg",
                              attrs: { label: "", outlined: "" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.ticketData.category) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [_c("v-divider")], 1),
                  _vm.checkPermission("secondaryFunctions")
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "ml-6 mt-1 pl-0 pt-0" },
                            [
                              _vm.companyExcavatorType &&
                              _vm.ticketData.state &&
                              _vm.ticketData.state === "NC"
                                ? _c("SecondaryFunctionsNC", {
                                    attrs: { "ticket-details": _vm.ticketData },
                                    on: {
                                      getTicketFromCenter:
                                        _vm.getTicketFromCenter,
                                      retransmitTicketSuccess:
                                        _vm.retransmitTicketSuccess,
                                      cancelTicketSuccess:
                                        _vm.cancelTicketSuccess,
                                      hourNoticeTicketSuccess:
                                        _vm.hourNoticeTicketSuccess,
                                    },
                                  })
                                : _vm._e(),
                              _vm.companyExcavatorType &&
                              _vm.ticketData.state &&
                              _vm.ticketData.state === "OH"
                                ? _c("SecondaryFunctionsOH", {
                                    attrs: { "ticket-details": _vm.ticketData },
                                    on: {
                                      getTicketFromCenter:
                                        _vm.getTicketFromCenter,
                                      retransmitTicketSuccess:
                                        _vm.retransmitTicketSuccess,
                                      cancelTicketSuccess:
                                        _vm.cancelTicketSuccess,
                                    },
                                  })
                                : _vm._e(),
                              _vm.companyExcavatorType &&
                              _vm.ticketData.state &&
                              _vm.ticketData.state === "IL"
                                ? _c("SecondaryFunctionJulie", {
                                    attrs: { "ticket-details": _vm.ticketData },
                                    on: {
                                      getTicketFromCenter:
                                        _vm.getTicketFromCenter,
                                      retransmitTicketSuccess:
                                        _vm.retransmitTicketSuccess,
                                      cancelTicketSuccess:
                                        _vm.cancelTicketSuccess,
                                      extendTicketSuccess:
                                        _vm.extendTicketSuccess,
                                      secondNoticeTicketSuccess:
                                        _vm.secondNoticeTicketSuccess,
                                    },
                                  })
                                : _vm._e(),
                              _vm.companyExcavatorType &&
                              _vm.ticketData.state &&
                              _vm.ticketData.state === "CO"
                                ? _c("SecondaryFunctionsCO", {
                                    attrs: { "ticket-details": _vm.ticketData },
                                    on: {
                                      getTicketFromCenter:
                                        _vm.getTicketFromCenter,
                                      addMembersTicketSuccess:
                                        _vm.addMembersTicketSuccess,
                                      cancelTicketSuccess:
                                        _vm.cancelTicketSuccess,
                                      extendTicketSuccess:
                                        _vm.extendTicketSuccess,
                                      relocateRefreshTicketSuccess:
                                        _vm.relocateRefreshTicketSuccess,
                                      commentTicketSuccess:
                                        _vm.commentTicketSuccess,
                                      secondNoticeTicketSuccess:
                                        _vm.secondNoticeTicketSuccess,
                                      utilityNotFoundTicketSuccess:
                                        _vm.utilityNotFoundTicketSuccess,
                                    },
                                  })
                                : _vm._e(),
                              _vm.companyExcavatorType &&
                              _vm.ticketData.state &&
                              _vm.ticketData.state === "VA"
                                ? _c("SecondaryFunctionVups", {
                                    attrs: { "ticket-details": _vm.ticketData },
                                    on: {
                                      getTicketFromCenter:
                                        _vm.getTicketFromCenter,
                                      addMembersTicketSuccess:
                                        _vm.addMembersTicketSuccess,
                                      cancelTicketSuccess:
                                        _vm.cancelTicketSuccess,
                                      extendTicketSuccess:
                                        _vm.extendTicketSuccess,
                                      relocateRefreshTicketSuccess:
                                        _vm.relocateRefreshTicketSuccess,
                                      commentTicketSuccess:
                                        _vm.commentTicketSuccess,
                                      secondNoticeTicketSuccess:
                                        _vm.secondNoticeTicketSuccess,
                                      utilityNotFoundTicketSuccess:
                                        _vm.utilityNotFoundTicketSuccess,
                                      hourDeepNoticeTicketSuccess:
                                        _vm.hourDeepNoticeTicketSuccess,
                                    },
                                  })
                                : _vm._e(),
                              _vm.companyExcavatorType &&
                              _vm.ticketData.state &&
                              _vm.ticketData.state === "UT"
                                ? _c("SecondaryFunctionUtah", {
                                    attrs: { "ticket-details": _vm.ticketData },
                                    on: {
                                      getTicketFromCenter:
                                        _vm.getTicketFromCenter,
                                      addMembersTicketSuccess:
                                        _vm.addMembersTicketSuccess,
                                      cancelTicketSuccess:
                                        _vm.cancelTicketSuccess,
                                      extendTicketSuccess:
                                        _vm.extendTicketSuccess,
                                      relocateRefreshTicketSuccess:
                                        _vm.relocateRefreshTicketSuccess,
                                      commentTicketSuccess:
                                        _vm.commentTicketSuccess,
                                      secondNoticeTicketSuccess:
                                        _vm.secondNoticeTicketSuccess,
                                      retransmitTicketSuccess:
                                        _vm.retransmitTicketSuccess,
                                      noResponseTicketSuccess:
                                        _vm.noResponseTicketSuccess,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "mt-4 pl-6",
                      class: _vm.$vuetify.breakpoint.smAndDown ? "ml-2" : "",
                    },
                    [
                      _c("v-row", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pa-0 text-body-2 text--darken-2 font-weight-regular mr-2",
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("user")) + ": ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ticketData.user_id
                                    ? _vm.companyUserMap[_vm.ticketData.user_id]
                                        .name
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("v-row", { staticClass: "mt-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pa-0 text-body-2 text--darken-2 font-weight-regular mr-2",
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("createdOnText")) + ": ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .moment(_vm.ticketData.created_at)
                                    .format("MM/DD/YYYY, hh:mm a")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("v-row", { staticClass: "mt-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pa-0 text-body-2 text--darken-2 font-weight-regular mr-2",
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("associatedProject")) + ": "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium",
                          },
                          [_vm._v(" " + _vm._s(_vm.associateProject) + " ")]
                        ),
                        _c("div", { attrs: { cols: "4" } }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "v-row",
        {
          staticClass: "mt-8",
          class: _vm.$vuetify.breakpoint.smAndDown ? "" : "pl-10 pr-4",
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.checkPermission("viewTicketOverview")
                ? _c("TicketOverview", {
                    attrs: { "ticket-details": _vm.ticketData },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.viewExpantionPanel && _vm.checkPermission("viewTickets")
        ? _c(
            "v-row",
            [
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c("TicketExpensionPanel", {
                    attrs: { "ticket-details": _vm.ticketData },
                  })
                : _vm._e(),
              _vm.$vuetify.breakpoint.smAndDown
                ? _c("TicketExpensionPanelMobileView", {
                    attrs: { "ticket-details": _vm.ticketData },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }