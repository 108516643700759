<script>
export default {
  computed: {
    centerMap() {
      return this.$store.getters.centerMap;
    },
  },
  methods: {
    async checkSecondaryFunctionsEligibility() {
      if (this.ticketData.center === "NC811") {
        return this.checkSecondaryFunctionsEligibilityNC();
      } else if (this.ticketData.center === "OHIO811") {
        return this.checkSecondaryFunctionsEligibilityOH();
      } else if (this.ticketData.center === "JULIE") {
        return this.checkSecondaryFunctionsEligibilityJulie();
      } else if (this.ticketData.center === "CO811") {
        return this.checkSecondaryFunctionsEligibilityCO();
      } else if (this.ticketData.center === "VA811") {
        return this.checkSecondaryFunctionsEligibilityVups();
      } else if (this.ticketData.center === "Blue Stakes of Utah 811") {
        return this.checkSecondaryFunctionsEligibilityUtah();
      } else {
        return false;
      }
    },
    async checkSecondaryFunctionsEligibilityNC() {
      const resp = await this.$store.dispatch("secondaryFunctionsEligibility", {
        center: this.centerMap[this.ticketDetails.center_id].abbrev,
        ticket: this.ticketDetails.center_ticket_id,
        revision: this.ticketDetails.revision,
      });
      if (resp) {
        this.cancelNC = resp.cancel;
        this.destroyedMarksNC = resp.destroyedMarks;
        this.retrasmitNC = resp.retransmit;
        this.threeHourNoticeNC = resp.threeHourNotice;
        this.updateNC = resp.update;
      }
    },
    async checkSecondaryFunctionsEligibilityOH() {
      const resp = await this.$store.dispatch("secondaryFunctionsEligibility", {
        center: this.centerMap[this.ticketDetails.center_id].abbrev,
        ticket: this.ticketDetails.center_ticket_id,
        revision: this.ticketDetails.revision,
      });
      if (resp) {
        this.cancelOH = resp.cancel;
        this.updateOH = resp.update;
        this.repeatOH = resp.repeat;
        this.diginOH = resp.digin;
      }
    },
    async checkSecondaryFunctionsEligibilityJulie() {
      const resp = await this.$store.dispatch("secondaryFunctionsEligibility", {
        center: this.centerMap[this.ticketDetails.center_id].abbrev,
        ticket: this.ticketDetails.center_ticket_id,
        revision: this.ticketDetails.revision,
      });
      if (resp) {
        this.cancelJulie = resp.cancel;
        this.secondNoticeJulie = resp.secondNotice;
        this.retransmitJulie = resp.retransmit;
        this.extendJulie = resp.extend;
      }
    },
    async checkSecondaryFunctionsEligibilityCO() {
      const resp = await this.$store.dispatch("secondaryFunctionsEligibility", {
        center: this.centerMap[this.ticketDetails.center_id].abbrev,
        ticket: this.ticketDetails.center_ticket_id,
        revision: this.ticketDetails.revision,
      });
      if (resp) {
        this.cancelCO = resp.cancel;
        this.commentCO = resp.comment;
        this.addMembersCO = resp.addMembers;
        this.relocateRefreshCO = resp.relocateRefresh;
        this.secondNoticeCO = resp.secondNotice;
        this.updateCO = resp.update;
        this.utilityNotFoundCO = resp.utilityNotFound;
      }
    },
    async checkSecondaryFunctionsEligibilityVups() {
      const resp = await this.$store.dispatch("secondaryFunctionsEligibility", {
        center: this.centerMap[this.ticketDetails.center_id].abbrev,
        ticket: this.ticketDetails.center_ticket_id,
        revision: this.ticketDetails.revision,
      });
      if (resp) {
        this.cancelVups = resp.cancel;
        this.updateTicketVups = resp.update;
        this.threeHourNoticeTicket = resp.threeHourNotice;
        this.threeHourDeepNoticeTicket = resp.threeHourNotice;
        this.correctionTicket = resp.correction;
        this.remarkTicket = resp.remark;
        this.commentTicket = resp.comment;
      }
    },
    async checkSecondaryFunctionsEligibilityUtah() {
      const resp = await this.$store.dispatch("secondaryFunctionsEligibility", {
        center: this.centerMap[this.ticketDetails.center_id].abbrev,
        ticket: this.ticketDetails.center_ticket_id,
        revision: this.ticketDetails.revision,
      });
      if (resp) {
        this.cancelUtah = resp.cancel;
        this.updateTicketUtah = resp.update;
        this.retransmitTicket = resp.retransmit;
        this.secondNoticeTicket = resp.secondNotice;
        this.remarkTicket = resp.remark;
        this.commentTicket = resp.comment;
        this.noResponseTicket = resp.noResponse;
      }
    },
  },
};
</script>
