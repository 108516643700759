<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-row no-gutters>
        <v-btn plain class="mt-2" @click="$emit('hideTicketDetails')"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span class="grey--text text--darken-4 text-h5 font-weight-medium mt-3">
          {{ ticketData.center_ticket_id }} - {{ ticketData.revision }}</span
        >

        <v-col
          v-if="
            $vuetify.breakpoint.mdAndUp && checkPermission('secondaryFunctions')
          "
          class="ml-8 mt-1 d-flex justify-end pt-0"
        >
          <SecondaryFunctionsNC
            v-if="
              companyExcavatorType &&
              ticketData.state &&
              ticketData.state === 'NC'
            "
            :ticket-details="ticketData"
            @getTicketFromCenter="getTicketFromCenter"
            @retransmitTicketSuccess="retransmitTicketSuccess"
            @cancelTicketSuccess="cancelTicketSuccess"
            @hourNoticeTicketSuccess="hourNoticeTicketSuccess"
          />
          <SecondaryFunctionsOH
            v-if="
              companyExcavatorType &&
              ticketData.state &&
              ticketData.state === 'OH'
            "
            :ticket-details="ticketData"
            @getTicketFromCenter="getTicketFromCenter"
            @repeatTicketSuccess="repeatTicketSuccess"
            @cancelTicketSuccess="cancelTicketSuccess"
          />
          <SecondaryFunctionJulie
            v-if="
              companyExcavatorType &&
              ticketData.state &&
              ticketData.state === 'IL'
            "
            :ticket-details="ticketData"
            @getTicketFromCenter="getTicketFromCenter"
            @retransmitTicketSuccess="retransmitTicketSuccess"
            @cancelTicketSuccess="cancelTicketSuccess"
            @extendTicketSuccess="extendTicketSuccess"
            @secondNoticeTicketSuccess="secondNoticeTicketSuccess"
          />
          <SecondaryFunctionsCO
            v-if="
              companyExcavatorType &&
              ticketData.state &&
              ticketData.state === 'CO'
            "
            :ticket-details="ticketData"
            @getTicketFromCenter="getTicketFromCenter"
            @addMembersTicketSuccess="addMembersTicketSuccess"
            @cancelTicketSuccess="cancelTicketSuccess"
            @extendTicketSuccess="extendTicketSuccess"
            @relocateRefreshTicketSuccess="relocateRefreshTicketSuccess"
            @commentTicketSuccess="commentTicketSuccess"
            @secondNoticeTicketSuccess="secondNoticeTicketSuccess"
            @utilityNotFoundTicketSuccess="utilityNotFoundTicketSuccess"
          />
          <SecondaryFunctionVups
            v-if="
              companyExcavatorType &&
              ticketData.state &&
              ticketData.state === 'VA'
            "
            :ticket-details="ticketData"
            @getTicketFromCenter="getTicketFromCenter"
            @addMembersTicketSuccess="addMembersTicketSuccess"
            @cancelTicketSuccess="cancelTicketSuccess"
            @extendTicketSuccess="extendTicketSuccess"
            @relocateRefreshTicketSuccess="relocateRefreshTicketSuccess"
            @commentTicketSuccess="commentTicketSuccess"
            @secondNoticeTicketSuccess="secondNoticeTicketSuccess"
            @utilityNotFoundTicketSuccess="utilityNotFoundTicketSuccess"
            @hourDeepNoticeTicketSuccess="hourDeepNoticeTicketSuccess"
            @hourNoticeTicketSuccess="hourNoticeTicketSuccess"
            @remarkTicketSuccess="remarkTicketSuccess"
            @correctionTicketSuccess="correctionTicketSuccess"
            @updateTicketSuccess="updateTicketSuccess"
          />
          <SecondaryFunctionUtah
            v-if="
              companyExcavatorType &&
              ticketData.state &&
              ticketData.state === 'UT'
            "
            :ticket-details="ticketData"
            @getTicketFromCenter="getTicketFromCenter"
            @addMembersTicketSuccess="addMembersTicketSuccess"
            @cancelTicketSuccess="cancelTicketSuccess"
            @extendTicketSuccess="extendTicketSuccess"
            @remarkTicketSuccess="remarkTicketSuccess"
            @commentTicketSuccess="commentTicketSuccess"
            @secondNoticeTicketSuccess="secondNoticeTicketSuccess"
            @retransmitTicketSuccess="retransmitTicketSuccess"
            @noResponseTicketSuccess="noResponseTicketSuccess"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4">
        <v-col cols="5" class="ml-16">
          <v-chip label outlined class="mr-4 rounded-lg">
            {{
              ticketData.center_id
                ? centerMap[ticketData.center_id].center_name
                : ""
            }}
          </v-chip>
          <v-chip label outlined class="mr-4 rounded-lg">
            <v-icon
              class="mr-2"
              small
              :color="priorityIconColor(ticketData.priority)"
            >
              {{ priorityIcon(ticketData.priority) }}
            </v-icon>
            {{ ticketData.priority }}
          </v-chip>
          <v-chip label outlined class="rounded-lg mr-4">
            {{ ticketData.type }}
          </v-chip>
          <v-chip label outlined class="rounded-lg">
            {{ ticketData.category }}
          </v-chip>
        </v-col>
        <v-col class="d-flex justify-end">
        </v-col></v-row
      >
      <v-row>
        <v-divider></v-divider>
      </v-row>
      <div class="mt-8 pl-10">
        <v-row>
          <div class="pa-0 text-body-2 text--darken-2 font-weight-regular mr-2">
            {{ $t("user") }}:
          </div>
          <div
            class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
          >
            {{
              ticketData.user_id ? companyUserMap[ticketData.user_id].name : ""
            }}
          </div>
          <v-divider vertical class="mr-4"></v-divider>
          <div class="pa-0 text-body-2 text--darken-2 font-weight-regular mr-2">
            {{ $t("createdOnText") }}:
          </div>
          <div
            class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
          >
            {{ moment(ticketData.created_at).format("MM/DD/YYYY, hh:mm a") }}
          </div>
          <v-divider vertical class="mr-4"></v-divider>

          <div class="pa-0 text-body-2 text--darken-2 font-weight-regular mr-2">
            {{ $t("associatedProject") }}:
          </div>
          <div
            class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
          >
            {{ associateProject }}
          </div>
          <div cols="4"></div>
        </v-row>
      </div>
    </div>
    <v-row v-else>
      <v-col cols="12" class="pt-2 px-0">
        <v-row no-gutters>
          <v-btn plain min-width="40" @click="$emit('hideTicketDetails')"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
          <v-col class="d-flex justify-end">
          </v-col>
        </v-row>
        <v-row no-gutters class="pl-5 pr-4">
          <span
            class="grey--text text--darken-4 text-h5 font-weight-medium mt-3"
          >
            {{ ticketData.center_ticket_id }} - {{ ticketData.revision }}</span
          >
        </v-row>
        <v-row no-gutters class="pl-5 pr-6 mt-4 pb-4">
          <v-col cols="12">
            <v-chip label outlined class="mr-4 rounded-lg">
              {{
                ticketData.center_id
                  ? centerMap[ticketData.center_id].center_name
                  : ""
              }}
            </v-chip>
            <v-chip label outlined class="mr-4 rounded-lg">
              <v-icon
                class="mr-2"
                small
                :color="priorityIconColor(ticketData.priority)"
              >
                {{ priorityIcon(ticketData.priority) }}
              </v-icon>
              {{ ticketData.priority }}
            </v-chip>
            <v-chip label outlined class="mr-4 rounded-lg">
              {{ ticketData.type }}
            </v-chip>
            <v-chip label outlined class="rounded-lg">
              {{ ticketData.category }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row v-if="checkPermission('secondaryFunctions')">
          <v-col class="ml-6 mt-1 pl-0 pt-0">
            <SecondaryFunctionsNC
              v-if="
                companyExcavatorType &&
                ticketData.state &&
                ticketData.state === 'NC'
              "
              :ticket-details="ticketData"
              @getTicketFromCenter="getTicketFromCenter"
              @retransmitTicketSuccess="retransmitTicketSuccess"
              @cancelTicketSuccess="cancelTicketSuccess"
              @hourNoticeTicketSuccess="hourNoticeTicketSuccess"
            />
            <SecondaryFunctionsOH
              v-if="
                companyExcavatorType &&
                ticketData.state &&
                ticketData.state === 'OH'
              "
              :ticket-details="ticketData"
              @getTicketFromCenter="getTicketFromCenter"
              @retransmitTicketSuccess="retransmitTicketSuccess"
              @cancelTicketSuccess="cancelTicketSuccess"
            />
            <SecondaryFunctionJulie
              v-if="
                companyExcavatorType &&
                ticketData.state &&
                ticketData.state === 'IL'
              "
              :ticket-details="ticketData"
              @getTicketFromCenter="getTicketFromCenter"
              @retransmitTicketSuccess="retransmitTicketSuccess"
              @cancelTicketSuccess="cancelTicketSuccess"
              @extendTicketSuccess="extendTicketSuccess"
              @secondNoticeTicketSuccess="secondNoticeTicketSuccess"
            />
            <SecondaryFunctionsCO
              v-if="
                companyExcavatorType &&
                ticketData.state &&
                ticketData.state === 'CO'
              "
              :ticket-details="ticketData"
              @getTicketFromCenter="getTicketFromCenter"
              @addMembersTicketSuccess="addMembersTicketSuccess"
              @cancelTicketSuccess="cancelTicketSuccess"
              @extendTicketSuccess="extendTicketSuccess"
              @relocateRefreshTicketSuccess="relocateRefreshTicketSuccess"
              @commentTicketSuccess="commentTicketSuccess"
              @secondNoticeTicketSuccess="secondNoticeTicketSuccess"
              @utilityNotFoundTicketSuccess="utilityNotFoundTicketSuccess"
            />
            <SecondaryFunctionVups
              v-if="
                companyExcavatorType &&
                ticketData.state &&
                ticketData.state === 'VA'
              "
              :ticket-details="ticketData"
              @getTicketFromCenter="getTicketFromCenter"
              @addMembersTicketSuccess="addMembersTicketSuccess"
              @cancelTicketSuccess="cancelTicketSuccess"
              @extendTicketSuccess="extendTicketSuccess"
              @relocateRefreshTicketSuccess="relocateRefreshTicketSuccess"
              @commentTicketSuccess="commentTicketSuccess"
              @secondNoticeTicketSuccess="secondNoticeTicketSuccess"
              @utilityNotFoundTicketSuccess="utilityNotFoundTicketSuccess"
              @hourDeepNoticeTicketSuccess="hourDeepNoticeTicketSuccess"
            />
            <SecondaryFunctionUtah
              v-if="
                companyExcavatorType &&
                ticketData.state &&
                ticketData.state === 'UT'
              "
              :ticket-details="ticketData"
              @getTicketFromCenter="getTicketFromCenter"
              @addMembersTicketSuccess="addMembersTicketSuccess"
              @cancelTicketSuccess="cancelTicketSuccess"
              @extendTicketSuccess="extendTicketSuccess"
              @relocateRefreshTicketSuccess="relocateRefreshTicketSuccess"
              @commentTicketSuccess="commentTicketSuccess"
              @secondNoticeTicketSuccess="secondNoticeTicketSuccess"
              @retransmitTicketSuccess="retransmitTicketSuccess"
              @noResponseTicketSuccess="noResponseTicketSuccess"
            />
          </v-col>
        </v-row>
        <div
          class="mt-4 pl-6"
          :class="$vuetify.breakpoint.smAndDown ? 'ml-2' : ''"
        >
          <v-row>
            <div
              class="pa-0 text-body-2 text--darken-2 font-weight-regular mr-2"
            >
              {{ $t("user") }}:
            </div>
            <div
              class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
            >
              {{
                ticketData.user_id
                  ? companyUserMap[ticketData.user_id].name
                  : ""
              }}
            </div>
          </v-row>
          <v-row class="mt-4">
            <div
              class="pa-0 text-body-2 text--darken-2 font-weight-regular mr-2"
            >
              {{ $t("createdOnText") }}:
            </div>
            <div
              class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
            >
              {{ moment(ticketData.created_at).format("MM/DD/YYYY, hh:mm a") }}
            </div>
          </v-row>
          <v-row class="mt-4">
            <div
              class="pa-0 text-body-2 text--darken-2 font-weight-regular mr-2"
            >
              {{ $t("associatedProject") }}:
            </div>
            <div
              class="pa-0 pr-4 grey--text text-body-2 text--darken-4 font-weight-medium"
            >
              {{ associateProject }}
            </div>
            <div cols="4"></div>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mt-8"
      :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-10 pr-4'"
    >
      <v-col cols="12">
        <TicketOverview
          v-if="checkPermission('viewTicketOverview')"
          :ticket-details="ticketData"
        />
      </v-col>
    </v-row>
    <v-row v-if="viewExpantionPanel && checkPermission('viewTickets')">
      <TicketExpensionPanel
        v-if="$vuetify.breakpoint.mdAndUp"
        :ticket-details="ticketData"
      />
      <TicketExpensionPanelMobileView
        v-if="$vuetify.breakpoint.smAndDown"
        :ticket-details="ticketData"
      />
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import EditTicket from "@/mixins/EditTicket";
import TicketSecondaryFunctions from "@/mixins/TicketSecondaryFunctions";
import UserDataMixin from "@/mixins/UserData";
import { bus } from "@/main";

export default {
  mixins: [EditTicket, TicketSecondaryFunctions, UserDataMixin],
  name: "TickDetails",
  components: {
    TicketOverview: () => import("./TicketOverview.vue"),
    TicketExpensionPanel: () => import("./TicketExpensionPanel.vue"),
    TicketExpensionPanelMobileView: () =>
      import("./TicketExpensionPanelMobileView.vue"),
    SecondaryFunctionsNC: () =>
      import("./secondary-functions/ncocc/SecondaryFunctionsNC"),
    SecondaryFunctionsOH: () =>
      import("./secondary-functions/ohio/SecondaryFunctionsOH.vue"),
    SecondaryFunctionJulie: () =>
      import("./secondary-functions/julie/SecondaryFunctionJulie.vue"),
    SecondaryFunctionsCO: () =>
      import("./secondary-functions/uncc/SecondaryFunctionsCO.vue"),
    SecondaryFunctionVups: () =>
      import("./secondary-functions/vups/SecondaryFunctionVups.vue"),
    SecondaryFunctionUtah: () =>
      import("./secondary-functions/utah/SecondaryFunctionUtah.vue"),
  },
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    cancelNC: false,
    destroyedMarksNC: false,
    retrasmitNC: false,
    threeHourNoticeNC: false,
    updateNC: false,
    currentTab: "Tasks",
    dialog: true,
    isViewProjectDetails: false,
    addProjectDialog: false,
    isUploadFiles: false,
    ticketData: {},
    viewExpantionPanel: false,
  }),
  computed: {
    tabs() {
      const allTabs = ["Tasks", "Board", "Tickets"];
      return allTabs;
    },
    companyUserMap() {
      return this.$store.state.User.companyUserMap;
    },
    centerMap() {
      return this.$store.getters.centerMap;
    },
    associateProject() {
      if (
        this.ticketData &&
        this.ticketData.options &&
        this.ticketData.options.excavationDetails &&
        this.ticketData.options.excavationDetails.associateProject
      ) {
        return this.ticketData.options.excavationDetails.associateProject
          .project_name;
      } else return "NA";
    },
    companyExcavatorType() {
      let excavatorType = "";
      if (
        this.$store.state.Company.company.centers &&
        this.$store.state.Company.company.centers.length > 0
      ) {
        excavatorType = this.$store.state.Company.company.centers.filter(
          (item) => {
            return item.center_id === this.ticketData.center_id;
          }
        );
      }
      return excavatorType[0];
    },
  },
  async created() {
    this.moment = moment;
    //bus.$on("updateTicket", this.getAttachmentsByReferenceId);
    bus.$on("filesUploded", (uploadedData) => {
      this.ticketDetails.attachments.push(...uploadedData);
      this.ticketData = Object.assign({}, this.ticketDetails);
    });
    bus.$on("updateAttachment", (attachmentData) => {
      const attachments = this.ticketDetails.attachments.map((item) => {
        if (item.attachment_id === attachmentData[0].attachment_id) {
          item = attachmentData[0];
        }
        return item;
      });
      this.ticketDetails.attachments = attachments;
      this.ticketData = Object.assign({}, this.ticketDetails);
    });

    bus.$on("deleteAttachments", (attachmentData) => {
      const deletedIds = attachmentData.map((item) => {
        return item.attachment_id;
      });
      const attachments = this.ticketDetails.attachments.filter((item) => {
        return !deletedIds.includes(item.attachment_id);
      });
      this.ticketDetails.attachments = attachments;
      this.ticketData = Object.assign({}, this.ticketDetails);
    });
  },
  async mounted() {
    this.ticketDetails.center =
      this.centerMap[this.ticketDetails.center_id].center_name;
    this.ticketData = Object.assign({}, this.ticketDetails);
    await this.getTicketFromCenter();
    this.getAttachmentsByReferenceId();
  },
  methods: {
    async hourNoticeTicketSuccess(ticketNumber, ticketRevision) {
      this.secondaryFnSuccessAlert(ticketNumber, ticketRevision, "3HourNotice");
    },
    async hourDeepNoticeTicketSuccess(ticketNumber, ticketRevision) {
      this.secondaryFnSuccessAlert(
        ticketNumber,
        ticketRevision,
        "3HourDeepNotice"
      );
    },

    async relocateRefreshTicketSuccess(ticketNumber, ticketRevision) {
      this.secondaryFnSuccessAlert(
        ticketNumber,
        ticketRevision,
        "relocateRefresh"
      );
    },
    async correctionTicketSuccess(ticketNumber, ticketRevision) {
      this.secondaryFnSuccessAlert(ticketNumber, ticketRevision, "correction");
    },
    async updateTicketSuccess(ticketNumber, ticketRevision) {
      this.secondaryFnSuccessAlert(ticketNumber, ticketRevision, "update");
    },
    async commentTicketSuccess(ticketNumber, ticketRevision) {
      this.secondaryFnSuccessAlert(ticketNumber, ticketRevision, "commented");
    },
    async extendTicketSuccess(ticketNumber, ticketRevision) {
      this.secondaryFnSuccessAlert(ticketNumber, ticketRevision, "extend");
    },
    async secondNoticeTicketSuccess(ticketNumber, ticketRevision) {
      this.secondaryFnSuccessAlert(
        ticketNumber,
        ticketRevision,
        "secondNotice"
      );
    },
    async utilityNotFoundTicketSuccess(ticketNumber, ticketRevision) {
      this.secondaryFnSuccessAlert(
        ticketNumber,
        ticketRevision,
        "utilityNotFound"
      );
    },
    async retransmitTicketSuccess(
      retransmitTicketNumber,
      retransmitTicketRevision
    ) {
      this.secondaryFnSuccessAlert(
        retransmitTicketNumber,
        retransmitTicketRevision,
        "retransmitted"
      );
    },
    async repeatTicketSuccess(
      retransmitTicketNumber,
      retransmitTicketRevision
    ) {
      this.secondaryFnSuccessAlert(
        retransmitTicketNumber,
        retransmitTicketRevision,
        "repeat"
      );
    },
    async remarkTicketSuccess(remarkTicketNumber, remarkTicketRevision) {
      this.secondaryFnSuccessAlert(
        remarkTicketNumber,
        remarkTicketRevision,
        "remark"
      );
    },
    async noResponseTicketSuccess(ticketNumber, ticketRevision) {
      this.secondaryFnSuccessAlert(ticketNumber, ticketRevision, "noResponse");
    },
    async addMembersTicketSuccess(
      retransmitTicketNumber,
      retransmitTicketRevision
    ) {
      this.secondaryFnSuccessAlert(
        retransmitTicketNumber,
        retransmitTicketRevision,
        "addMembers"
      );
    },
    secondaryFnSuccessAlert(ticketNumber, ticketRevision, type) {
      this.$emit("secondaryFnSuccessAlert", ticketNumber, ticketRevision, type);
    },
    async updateTicketDetails(editType) {
      await this.updateTicket(this.ticketData, editType);
      this.$router.push("/createTicket").catch();
    },
    async cancelTicketSuccess(cancelTicketNumber, cancelTicketRevision) {
      this.secondaryFnSuccessAlert(
        cancelTicketNumber,
        cancelTicketRevision,
        "cancelled"
      );
    },
    async getTicketFromCenter() {
      const resp = await this.$store.dispatch("getTicketsInfo", {
        center: this.centerMap[this.ticketDetails.center_id].abbrev,
        ticket: this.ticketDetails.center_ticket_id,
        revision: this.ticketDetails.revision,
      });
      this.ticketData = Object.assign(this.ticketDetails, resp);
      this.ticketData["centerData"] = {
        center: this.centerMap[this.ticketDetails.center_id].abbrev,
        ticket: this.ticketDetails.center_ticket_id,
      };
      this.$store.state.Ticket.geoJson = {
        type: "FeatureCollection",
        features: [this.ticketData.work_area],
      };
      this.$store.state.Ticket.ticketData.parcelExactInPlace = 0;
      this.viewExpantionPanel = true;
    },
    priorityIconColor(priority) {
      switch (priority) {
        case "RUSH":
          return "#fdd835";
        case "EMER":
          return "red";
        case "NONC":
          return "light-blue";
        default:
          return "grey";
      }
    },
    priorityIcon(priority) {
      switch (priority) {
        case "RUSH":
          // yellow circle exclamation
          return "mdi-bell";
        case "EMER":
          // red bell exclamation
          return "mdi-bell";
        case "NONC":
          // light blue circle exclamation
          return "mdi-bell";
        default:
          return "mdi-alert-octagon";
      }
    },
    getNextWorkDay(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    async getAttachmentsByReferenceId() {
      const payload = {};
      payload.referenceId = this.ticketDetails.ticket_id;
      const attachmentsResp = await this.$store.dispatch(
        "getAttachmentsByReferenceId",
        payload
      );
      if (attachmentsResp.status !== "error") {
        this.ticketDetails.attachments = attachmentsResp;
        this.ticketData = Object.assign({}, this.ticketDetails);
      } else {
        console.log("error");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
